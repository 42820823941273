import Uri from 'goog:goog.Uri';
import KeyCodes from 'goog:goog.events.KeyCodes';
import XhrIo from 'goog:goog.net.XhrIo';

import yext from 'goog:yext';

import {OauthButtons} from '/src/com/yext/platform/users/js/common/oauthbuttons';
import {openCookieDrawer} from '/src/com/yext/storm/js/osano/osano';
import {Button} from '/ui/components/button/button';
import {Checkbox} from '/ui/components/checkbox/checkbox';
import {Input} from '/ui/components/input/input';
import {slowRegisterComponentForJsonData} from '/ui/lib/easyreact';
import {logError} from '/ui/lib/errors';

import SsoIcon from '/src/com/yext/platform/users/public/images/sso_icon.svg';


// this "name":name pattern is needed to make the names work with advanced compilation
export function GlobalLogin(props) {
/**
 * State Diagram:
 *
 * isSso:           False  ------------------ clicks SSO button --------------->  True
 * isPassword:      False  -------- inputted username is password login --------> True
 * isNonUniqueSaml: False  -------- username is shared by multiple users -------> True
 * isIdp:           False  ------ username corresponds to an IDP only user -----> True
 * initiateSso:     False  - username corresponds to an SP only or SP+IDP user -> True
 */
  const [username, setUsername] = React.useState(props.username);
  // Derived either from URL query param or user
  const [accountId, setAccountId] = React.useState(props.b === undefined ? '' : props.b);
  // If we find users in multiple partitions, we need the user to clarify their business ID.
  const [promptBusinessId, setPromptBusinessId] = React.useState(false);
  // Error message to be displayed
  const [error, setError] = React.useState(props.errorMessage);
  // Whether to remember the username for future login attempts.
  const [rememberMe, setRememberMe] = React.useState(true);
  // Whether to show the content below the username form or not
  const [isSso, setIsSso] = React.useState(false);
  // Whether to disable the submit button or not
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = React.useState(false);

  React.useEffect(() => {
    // If the user presses enter, cause that event to find the user's partition
    const handleKeyShortcuts = event => {
      if (event.keyCode === KeyCodes.ENTER) {
        event.preventDefault();
        if (username || (promptBusinessId && accountId)) {
          // If the user presses enter and the field is populated, simulate the button click.
          getUserPartition();
        }
      }
    };

    document.addEventListener('keydown', handleKeyShortcuts);
    return () => {
      document.removeEventListener('keydown', handleKeyShortcuts);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, promptBusinessId, accountId]);

  /** Make API call to get a list of login types for a given username. */
  const getUserPartition = () => {
    if (username === null || username === undefined || username === '' || username.trim() === '') {
      setError(yext.msg('Please enter an email/username.'));
      return;
    }

    // deactivate the submit button when the user submits the username
    // while waiting for a response
    setIsSubmitBtnDisabled(true);

    const uri = new Uri('/users/login/getPartition');
    uri.setQueryData(Uri.QueryData.createFromMap({
      'username': username,
      'c': props['c'] ? props['c'] : '',
      'b': accountId,
      'rememberMe': rememberMe,
    }));
    XhrIo.send(
      uri,
      e => {
        if (e.target.isSuccess()) {
          // Controller returns instructions to redirect or prompt for business ID.
          handleResponse(e.target.getResponseJson());
        } else {
          setError(e.target.getResponseJson());
          setIsSubmitBtnDisabled(false);
        }
      },
      'GET',
      null,
      {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    );
  };

  const handleResponse = response => {
    switch (response['action']) {
      case 'REDIRECT':
        // If the controller definitively finds the partition, redirect there.
        window.location = response['url'];
        break;
      case 'MULTIPLE_PARTITIONS':
        // If multiple partitions are possible, ask for business ID.
        setPromptBusinessId(true);
        setIsSubmitBtnDisabled(false);
        break;
      case 'NULL_OR_EMPTY_USERNAME':
        // If a null or empty username is submitted, set error.
        setError(yext.msg('Please enter a username.'));
        setIsSubmitBtnDisabled(false);
    }
  };

  const forgotPasswordSignupSection =
    props['isYextDomain'] && !props['isMobileRedirect']
      ? <div className="yext-login__forgot-or-signup">
        <a href="/users/forgotpassword">{yext.msg('Forgot password?')}</a>
        <span>
          {yext.msg('Don\'t have an account? ')}
          <a href="/free-trial">{yext.msg('Sign up')}</a>
        </span>
      </div>
      : <a className="yext-login__forgot-only" href="/users/forgotpassword">
        {yext.msg('Forgot password?')}
      </a>;

  const rememberMeLabel =
    props['isMobileRedirect']
      ? yext.msg('Remember me (uncheck this if using a public or shared device)')
      : yext.msg('Remember me (uncheck this if using a public or shared computer)');

  // The input field will either be for the username or the business ID.
  const userInput =
    promptBusinessId
      ? <Input
        id="login-account-id"
        type="text"
        label={yext.msg('Account ID')}
        value={accountId}
        onChange={newAccountId => setAccountId(newAccountId)}
        placeholder={yext.msg('Account ID')}
        hideLabel={true}/>
      : <Input
        id="login-username"
        type="text"
        name="username"
        label={yext.msg('Username')}
        value={username}
        onChange={newUsername => setUsername(newUsername)}
        placeholder={yext.msg('Email / Username')}
        hideLabel={true}/>;

  return (
    <div>
      <div className="users__login-form">
        <div className="js-ajax-error center-align"></div>
        {error && <span id="error">{error}</span>}
        <div className="login-form" id="users-login-form">
          <input type="hidden" name="b" className="js-business-id" value={accountId} />
          <input type="hidden" className="js-redirect-url" value={props['redirectUrl']} />

          {userInput}

          <button
            id="submission-button"
            type="button"
            className="yext-login__button yext-login__button--primary yext-login__button--submit"
            onClick={getUserPartition}
            disabled={isSubmitBtnDisabled}
          >
            <span>{yext.msg('NEXT')}</span>
          </button>
        </div>

        {!isSso
        && <>
          <Checkbox
            label={rememberMeLabel}
            name="rememberMe"
            value={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            checked={rememberMe}
          />

          {forgotPasswordSignupSection}

          {renderEmployeeZendeskRedirectLogin(props['c'])}

          <div className="yext-login__divider">{yext.msg('or sign in with')}</div>
          <div className="yext-login__oauth-signup-options">
            {!props['passwordOnly']
            && <button
              type="button"
              id="sso-button"
              className="users__login-oauth"
              onClick={() => setIsSso(true)}
            >
              <SsoIcon />
              <span>SSO</span>
            </button>}

            {!props['isMobileRedirect']
              && <OauthButtons
                githubOAuthClientId={props['githubOAuthClientId']}
                googleOAuthClientId={props['googleOAuthClientId']}
                authenticityToken={props['authenticityToken']}
                accountId={accountId}
                redirectUrl={props['c']}
                setError={setError}/>}
          </div></>}

      </div>
      {props.isOsanoEnabled
        && <Button className="yext__toggle-osano-cookie-drawer" chromeless={true} onClick={openCookieDrawer}>
          {yext.msg('Cookie Preferences')}
        </Button>}
    </div>
  );
}


/**
 * Conditionally renders a button used by Yext employees who are directed to
 * Yext login from the Zendesk mobile app's login flow.
 * It allows them to login to Yext via employee oauth so that they don't have to
 * leave the mobile app's login flow.
 *
 * @param redirectUrl the redirect url given via query param 'c'
 * @returns {React.ReactElement}
 */
function renderEmployeeZendeskRedirectLogin(redirectUrl) {
  try {
    const redirectsToZendesk = redirectUrl && redirectUrl.startsWith('/idp/zendesk');
    if (!redirectsToZendesk) {
      return null;
    }
    return (
      <div className="center-align">
        <a href="https://yext.okta.com/app/zendesk/exk1hm8btz2I3OHC71d8/sso/saml">
          {yext.msg('I am an Agent')}
        </a>
      </div>
    );
  } catch (e) {
    logError(e);
  }
}

slowRegisterComponentForJsonData('GlobalLogin', GlobalLogin);
